import { combineReducers } from 'redux';
import demoReducer from './DemoReducer';
import loggedInStatus from './loggedInStatus';
import swappedPositionsReducer from './swappedPositionsReducer';

const rootReducer = combineReducers({
  demoReducer,
  loggedInStatus,
  swappedPositions: swappedPositionsReducer,
});

export default rootReducer;
