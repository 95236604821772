import actions from '../actions';

const initialState = {
  updateTimeStamp: '',
};

const swappedPositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_SWAPPED_POSITION_COUNT:
      return {
        ...state,
        updateTimeStamp: action.payload,
      };
    default:
      return state;
  }
};

export default swappedPositionsReducer;
