/* eslint-disable max-len */
import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { showNotification } from '@mantine/notifications';
import { useMantineColorScheme } from '@mantine/core';
import commonStyle from '../common.module.css';
import TraderListingHeader from '../../../components/ListingTables/traderListing/TraderListingHeader';
import Data from './dummyData';
import TraderListingRow from '../../../components/ListingTables/traderListing/TraderListingRow';
import Layout from '../../Layout/Layout';
import { apiWrapWithErrorWithData } from '../../../utilities/apis/apiHelpers';
import { tradersListApi } from '../../../utilities/apis/traders';
import { loadingStates } from '../../../utilities/general';
import TraderLoadingPlaceholder from '../../../components/LoadingPlaceholder/TraderLoadingPlaceholder';

const TradersDetails = lazy(() => import('../../DetailPages/CommonDetailsComponent/TradersDetails'));

function TraderListing() {
  const [listingConfigs, setListingConfigs] = useState({
    dataLoading: loadingStates.NO_ACTIVE_REQUEST,
    data: Data,
    tradersListData: null,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();

  const detailsOverlayShow = !!location.pathname.includes('/traders-details');

  const getTradersListingData = async () => {
    setListingConfigs({
      ...listingConfigs,
      dataLoading: loadingStates.LOADING,
    });
    const resp = await apiWrapWithErrorWithData(tradersListApi());
    if (resp?.success) {
      setListingConfigs((prevValue) => ({
        ...prevValue,
        dataLoading: loadingStates.FINISHED,
        tradersListData: resp.data,
      }));
    } else {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load traders list.',
      });
    }
  };

  useEffect(() => {
    getTradersListingData();
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleClose = () => {
    navigate('/traders-list');
  };

  return (
    <div
      className={detailsOverlayShow ? `w-full flex h-full ${commonStyle.modalOverlay}` : 'w-full flex'}
      aria-hidden="false"
      onClick={handleClose}
    >
      <Layout>

        <div className={`flex flex-col w-full ${commonStyle.rightSection}`}>
          {/* <div className="w-full bg-white sticky top-0 z-10"> */}
          <div className={`${commonStyle.stickyMainHeaderbox} ${colorScheme === 'dark' ? 'border-b border-neutral-500' : 'border-b border-[1px solid #D9D9D9]'}`}>
            {/* <div className={`w-full bg-white px-3 ${commonStyle.headerMainContainer} ${commonStyle.stickyMainHeader}`}> */}
            {/* <FilterHeader
                appliedFilters={listingConfigs.appliedFilters}
                setListingFilters={(filters) => {
                  setListingConfigs({
                    ...listingConfigs,
                    metaData: null,
                    infiniteScrollEnabled: false,
                    appliedFilters: {
                      ...listingConfigs.appliedFilters,
                      ...filters,
                    },
                  });
                }}
                selectedValue={listingConfigs.sortBy}
                setSelected={(sortBy, sortDir) => {
                  setListingConfigs({
                    ...listingConfigs,
                    metaData: null,
                    infiniteScrollEnabled: false,
                    sortBy,
                    sortOrientation: sortDir,
                  });
                }}
                sortDirection={listingConfigs.sortOrientation}
                listingConfigs={listingConfigs}
                setListingConfigs={(args) => {
                  setListingConfigs({
                    ...args,
                    metaData: null,
                    infiniteScrollEnabled: false,
                  });
                }}
                length={listingConfigs.data && listingConfigs.data.length}
              /> */}
            {/* </div> */}
            <div className={`${colorScheme === 'dark' ? 'bg-black' : 'bg-white'} w-full z-10 max-h-16 ${commonStyle.tableHeaderContainer} ${commonStyle.stickyFilterHeader}`}>
              <div
                className="flex-col mx-auto px-4 pt-3 pb-0"
                style={{
                  maxWidth: '1240px',
                }}
              >
                <TraderListingHeader />
              </div>
            </div>
          </div>
          <div
            className="flex-col mx-auto mt-4 px-4 w-full"
            style={{
              maxWidth: '1240px', overflowY: 'hidden',
            }}
          >
            {
              listingConfigs.dataLoading === loadingStates.FINISHED
              && listingConfigs.tradersListData && listingConfigs.tradersListData?.map(
                (trader, index) => (
                  <TraderListingRow
                    tab="Trader"
                    data={trader}
                    dataId={index}
                  />
                ),
              )
            }
            {
              listingConfigs.dataLoading === loadingStates.LOADING
              && <TraderLoadingPlaceholder />
            }

          </div>
        </div>
        {/*    </div> */}
        {/*  </div> */}
      </Layout>
      {
        detailsOverlayShow && (
          <Suspense>
            <TradersDetails />
          </Suspense>
        )
      }
    </div>

  );
}
export default TraderListing;
