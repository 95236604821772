import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const tradersListApi = () => axios.get(`${constants.tradersApiHost}/traders/list`, {
  headers: {
    ...headersProvider(),
  },
});

export const tradersOverviewApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/overview`, {
  headers: {
    ...headersProvider(),
  },
});

export const tradersAssetsApi = ({ traderId, startDate, endDate }) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/assets`, {
  params: {
    startDate,
    endDate,
  },
  headers: {
    ...headersProvider(),
  },
});

export const tradersChartSnapshotApi = ({ traderId }) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/chart-data-snapshot`, {
  headers: {
    ...headersProvider(),
  },
});
export const tradersChartDataApi = ({
  traderId, chartType, startDate, endDate,
}) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/chart-data/${chartType}`, {
  params: {
    startDate,
    endDate,
  },
  headers: {
    ...headersProvider(),
  },
});
export const tradersOrdersApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/orders`, {
  headers: {
    ...headersProvider(),
  },
});
export const tradersMarketsApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/markets`, {
  headers: {
    ...headersProvider(),
  },
});

export const tradersPositionsApi = (traderId, status) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/positions?status=${status}`, {
  headers: {
    ...headersProvider(),
  },
});

export const tradersTransfersApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/transfers`, {
  headers: {
    ...headersProvider(),
  },
});
export const setInterimLevelsApi = ({ traderId, positionId, interimLimits }) => axios.post(`${constants.tradersApiHost}/traders/${traderId}/positions/${positionId}/interim-limits`, {
  ...interimLimits,
},
{
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const traderWalletApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/wallet`, {
  headers: {
    ...headersProvider(),
  },
});

export const traderStakingApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/staking`, {
  headers: {
    ...headersProvider(),
  },
});

export const setLimitAndStopLevelsApi = ({ traderId, positionId, levels }) => axios.post(`${constants.tradersApiHost}/traders/${traderId}/positions/${positionId}/set-limits`, {
  ...levels,
},
{
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const traderPerformanceApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/performance`, {
  headers: {
    ...headersProvider(),
  },
});

export const V2traderStakingApi = (traderId) => axios.get(`${constants.tradersApiHost}/traders/${traderId}/staking-v2`, {
  headers: {
    ...headersProvider(),
  },
});

export const traderMovePositionsApi = (positionIds, target) => axios.put(`${constants.tradersApiHost}/company/company-data/move-position`,
  {
    positionIds,
    target,
  },
  {
    headers: {
      ...headersProvider(),
    },
  });
