/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  YAxis,
} from 'recharts';
import { format, isValid, parse } from 'date-fns';
import { useMantineColorScheme, Checkbox } from '@mantine/core';
import styles from './CompanyDataV2.module.css';
import { getMonthInfo, renderNumber } from '../../../utilities/general';
import useSorting from '../../../components/CommonFunction/useSorting';

export default function AreaChartsV2(props) {
  const {
    chartName, days, data,
    chartId,
  } = props;
  const { colorScheme } = useMantineColorScheme();
  const [checked, setChecked] = useState(false);

  const { items } = useSorting(data, 'date', 'descending');

  const DateFormatter = (date) => {
    const d = days === 180 ? parse(date, 'M yyyy', new Date()) : parse(date, 'yyyy-MM-dd', new Date());
    return isValid(d) ? days === 'Last 3 Months' ? format(d, 'LLL') : format(d, 'LLL dd') : null;
  };

  const CustomTooltip = (propss) => {
    const { payload } = propss;
    const assetValue = payload.map((val) => val?.payload);
    return (
      <div className="flex p-3 h-18 w-30 bg-white rounded-xl shadow-xl border border-gray-400">
        {
                    assetValue?.map((item, i) => {
                      const {
                        day, daySuffix, month, year,
                      } = getMonthInfo(item?.date);
                      return (
                        <div className="flex flex-col gap-1" key={i}>
                          <div className="text-base text-[#58667E] font-normal">
                            <span>{day}</span>
                            <sup>{daySuffix}</sup>
                            <span>{` ${month}, ${year}`}</span>
                          </div>
                          <div className={`flex ${item?.value > 0 ? 'text-[#4ECA2C]' : 'text-[#ED2D27]'} text-base font-normal justify-center`}>{`$${renderNumber(item?.value)}`}</div>
                        </div>
                      );
                    })
                }
      </div>
    );
  };

  const SplitCustomTooltip = (propss) => {
    const { payload } = propss;
    const asset = payload.map((val) => val);
    const profit = asset[0]?.payload?.profitValue;
    const loss = asset[0]?.payload?.lossValue;
    const {
      day, daySuffix, month, year,
    } = getMonthInfo(asset[0]?.payload.date);
    return (
      <div className="flex p-3 h-18 w-30 bg-white rounded-xl shadow-xl border border-gray-400">
        <div className="flex flex-col gap-1">
          <div className="text-base text-[#58667E] font-normal">
            <span>{day}</span>
            <sup>{daySuffix}</sup>
            <span>{` ${month}, ${year}`}</span>
          </div>
          <div>
            <div>
              Profit :
              <span className="text-[#4ECA2C]">{` ${renderNumber(profit, true)}`}</span>
            </div>
            <div>
              Loss :
              <span className="text-[#ED2D27]">{` ${renderNumber(loss, true)}`}</span>
            </div>
          </div>
        </div>

      </div>
    );
  };

  const CustomDot = (propss) => {
    const {
      cx, cy, payload,
    } = propss;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        stroke="white"
        strokeWidth={1.6}
        fill={payload?.value > 0 ? 'green' : 'red'}
      />
    );
  };

  // Calculate domain for YAxis when split view is enabled
  const calculateDomain = () => {
    if (!checked || !data) return ['auto', 'auto'];

    const maxProfit = Math.max(...data.map((item) => item.profitValue || 0));
    const minLoss = Math.min(...data.map((item) => item.lossValue || 0));

    // Add some padding to the domain
    const padding = Math.max(Math.abs(maxProfit), Math.abs(minLoss)) * 0.1;
    return [minLoss - padding, maxProfit + padding];
  };

  const gradientOffset = () => {
    const dataMax = data && Math.max(...data.map((i) => i.value));
    const dataMin = data && Math.min(...data.map((i) => i.value));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();
  return (
    <div className={`${styles.paddingLeft19} flex flex-col py-2 px-2 h-full`}>
      <div className="flex justify-between pt-3">
        <div className={`${styles.HeadingText} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'} flex items-baseline gap-1`}>
          {`${chartName}`}
          <span className="text-xs opacity-70 flex !items-center">{` - ${days}`}</span>
        </div>
        <div>
          <Checkbox
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
            label={<div className={`${colorScheme === 'dark' ? 'text-[#BEBFC0]' : 'text-[#757575]'} mr-2`}>Split P/L</div>}
            radius="xl"
            size="xs"
          />
        </div>
      </div>
      <div className="w-[100%] h-full">
        {items && (
        <ResponsiveContainer width="99%" height="99.59%">
          <AreaChart
            data={items}
            margin={{
              top: 37,
              bottom: 12,
              left: 20,
              right: 20,
            }}
          >
            <defs>
              <linearGradient id={chartId} x1={off === 1 || off === 0 ? '1' : '0'} y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="green" />
                <stop offset={off} stopColor="red" />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#4ECA2C" stopOpacity={1.5} />
                <stop offset={off} stopColor="#4ECA2C" stopOpacity={0.05} />
                <stop offset={off} stopColor="#EF494D" stopOpacity={0.05} />
                <stop offset="100%" stopColor="#EF494D" stopOpacity={1.5} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="profitGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#4ECA2C" stopOpacity={1.5} />
                <stop offset="95%" stopColor="#4ECA2C" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="lossGradient" x1="0" y1="1" x2="0" y2="0">
                <stop offset="0%" stopColor="#ED2D27" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ED2D27" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            {/* <defs>
                <linearGradient id="fillColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="20%" stopColor="#4ECA2C" stopOpacity={1.5} />
                  <stop offset="90%" stopColor="#4ECA2C" stopOpacity={0.05} />
                </linearGradient>
                <linearGradient id="fillColor2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#EF494D" stopOpacity={0.05} />
                  <stop offset={off} stopColor="#EF494D" stopOpacity={1.5} />
                </linearGradient>
              </defs> */}
            <Tooltip
              cursor={false}
              content={checked ? <SplitCustomTooltip /> : <CustomTooltip />}
            />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickFormatter={DateFormatter}
              tickSize={12}
              interval={days === '30 Days' ? 5 : 'preserveStartEnd'}
              style={{ fontSize: 10 }}
              axisLine={false}
              stroke="#A3AED0"
              padding={{ left: 15, right: 3 }}
            />
            <YAxis domain={calculateDomain()} hide />
            <ReferenceLine y={0} stroke="#C6D5DD" strokeWidth={2.5} />
            {checked ? (
              <>
                <Area
                  type="monotone"
                  dataKey="profitValue"
                  strokeWidth={1.9}
                  stroke="#4CAF50"
                  fill="url(#profitGradient)"
                  isAnimationActive={false}
                />
                <Area
                  type="monotone"
                  dataKey="lossValue"
                  strokeWidth={1.9}
                  stroke="#F44336"
                  fill="url(#lossGradient)"
                  isAnimationActive={false}
                />
              </>
            ) : (
              <Area
                type="monotone"
                strokeWidth={2.8}
                dataKey="value"
                stroke={`url(#${chartId})`}
                fill="url(#splitColor)"
                activeDot={<CustomDot />}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
